import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import React from 'react'

const Thanks = props => (
  <Layout>
    <Helmet
      title="Simiancraft"
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    />
    <div
      className="main"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <section className="section">
        <div className="container">
          <div className="content inner">
            <h1>Thank you!</h1>
            <p>Our team will be in contact soon.</p>
            <a href="/" className="button prev scrolly">
              Back to Simiancraft.com
            </a>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Thanks
